import { useCallback } from "react";
import useAddress from "../hooks/useAddress";
import axios from "axios";
import useSWR from "swr";
import { fetcherNoCreds } from "utils/fetcher";

export default () => {
  const address = useAddress();

  // const listLeaderboard = useCallback(async () => {
  //   const url = `${process.env.REACT_APP_AUTH_API_URL}/api/leaderboard/list`;
  //   const res = (await axios.get(url)).data;
  //   return res;
  // }, []);

  const createReferralCode = useCallback(
    async (address) => {
      const url = `${process.env.REACT_APP_AUTH_API_URL}/api/referral/create?address=${address}`;
      const res = (await axios.get(url)).data;
      return res;
    },
    [address]
  );

  // const listReferralCodes = useCallback(
  //   async (address) => {
  //     const url = `${process.env.REACT_APP_AUTH_API_URL}/api/referral_code/list?address=${address}`;
  //     const res = (await axios.get(url)).data;
  //     return res;
  //   },
  //   [address]
  // );

  const {
    data: myReferral,
    isLoading: myReferralLoading,
    mutate: mutateMyReferral,
  } = useSWR(
    `${process.env.REACT_APP_AUTH_API_URL}/api/referral/me?address=${address}`,
    fetcherNoCreds
  );

  return {
    myReferral: myReferral?.data?.[0],
    myReferralLoading,
    mutateMyReferral,
    hasReferralCode: myReferral?.data?.[0]?.referral_codes?.length > 0,
    createReferralCode,
    // listReferralCodes,
  };
};
