import BN, { BigNumber } from "bignumber.js";
import { Network } from "@injectivelabs/networks";
import useNetwork from "hooks/useNetwork";
import useURL from "hooks/useURL";
import { IS_DEVELOPMENT, NETWORK } from "libs/services";
import {
  FarmConfig,
  farms as FARMS_V1,
  farmsV2 as FARMS_V2,
  PublicFarmData,
} from "constants/farms";
import { toBase64 } from "@injectivelabs/sdk-ts";
import { contracts } from "../types/StakingPool";
import {
  FORCE_ON_CHAIN_FETCH,
  INJECTIVE_BURN_WALLET,
} from "constants/constants";
import {
  DOJO,
  MAINNET_TOKEN_ADDRESSES,
  POINT,
  TESTNET_TOKEN_ADDRESSES,
  USDC,
  USDT,
} from "constants/tokens";
import { multicallFetcher } from "utils/multicallFetcher";
import _ from "lodash";
import dayjs from "dayjs";
import { BigNumberInWei } from "@injectivelabs/utils";
import useWallet from "./useWallet";
import useDashboardAPI from "rest/useDashboardAPI.ts";
import createTimeout from "utils/createTimeout";
import { FarmData } from "../store/interfaces/farms.interface";
import useEphemeralStore from "../store/ephemeralStore";
import useSWR from "swr";
import { useEffect } from "react";

export const initialFarms = (version: number) => {
  return (version === 1 ? FARMS_V1 : FARMS_V2)[
    NETWORK === Network.Mainnet ? "mainnet" : "testnet"
  ]
    ?.filter((farm) => !farm.hidden)
    .reduce((dict, obj) => {
      dict[obj.name] = {
        ...obj,
        apr: "0",
        balance: 0,
        balanceUsd: 0,
        emissions: 0,
        pending_reward: "0",
        total_bond_amount: "0",
        tvl: 0,
        walletUsd: 0,
        walletBalance: 0,
        version: 1,
        lpPrice: 0,
      };
      return dict;
    }, {});
};

// How often to CHECK if farms need to be fetched (in milliseconds)
export const FETCH_FARMS_FREQUENCY_MS = 60000;

// Farms will be cached for below duration,
// and not fetched whilst cached.
// Regardless of FETCH_FARMS_FREQUENCY_MS.
export const CACHED_FARM_DURATION_SECS = 30;

const BIG_TEN = new BN(10);
const { StakingPool } = contracts;

function getAPR(
  distribution_schedule: [number, number, string][],
  stakeAmt: string,
  rewardTokenPrice: BigNumber,
  stakeTokenPrice: BigNumber
) {
  const schedule =
    distribution_schedule?.find((schedule) => {
      const now = Date.now() / 1000;
      return now > schedule[0] && now < schedule[1];
    }) ?? [];
  const unixTimeNow = dayjs().unix();

  if (unixTimeNow > schedule?.[1] || unixTimeNow < schedule?.[0]) {
    return "0";
  }
  const delta = schedule?.[1] - schedule?.[0];
  const totalRewardAmt = new BigNumberInWei(schedule?.[2]);
  const dailyRewardAmtInUSD = new BigNumberInWei(totalRewardAmt)
    .div(delta)
    .times(86400)
    .multipliedBy(rewardTokenPrice);
  const stakeAmtInUSD = new BigNumberInWei(stakeAmt).multipliedBy(
    stakeTokenPrice
  );

  const apr = dailyRewardAmtInUSD.div(stakeAmtInUSD).times(365).times(100);
  return apr.dp(2).toJSON();
}

function getEmissions(distribution_schedule: [number, number, string][]) {
  const schedule =
    distribution_schedule?.find((schedule) => {
      const now = Date.now() / 1000;
      return now > schedule[0] && now < schedule[1];
    }) ?? [];
  const unixTimeNow = dayjs().unix();
  if (unixTimeNow > schedule?.[1] || unixTimeNow < schedule?.[0]) {
    // always show the first distro schedule without worrying about time. but we make sure end time is not in the past
    return "0";
  }
  const delta = schedule?.[1] - schedule?.[0];
  const totalRewardAmt = new BigNumberInWei(schedule?.[2]);
  return totalRewardAmt
    .div(BIG_TEN.pow(18))
    .div(delta)
    .times(86400)
    .dp(2)
    .toString();
}

function getTVL(
  farm: PublicFarmData,
  token0Price: number,
  token1Price: number
) {
  const token0Decimals = new BN(10).pow(farm.token0.decimals);
  const token1Decimals = new BN(10).pow(farm.token1.decimals);
  const token0Supply = farm.vault?.lpInfo?.token0Supply;
  const token1Supply = farm.vault?.lpInfo?.token1Supply;
  // const lpSupply = farm.vault?.stakedBalance ?? 0

  const tvl = new BN(token0Supply)
    .times(token0Price)
    .div(token0Decimals)
    .plus(new BN(token1Supply).times(token1Price).div(token1Decimals))
    .times(farm.vault?.stakedBalance)
    .div(farm.vault?.lpInfo.total_supply)
    .toNumber();

  return tvl.toString() === "NaN" ? "0" : tvl.toString();
}

function getPriceOfLp(
  farm: PublicFarmData,
  token0Price: number,
  token1Price: number
) {
  const token0Decimals = new BN(10).pow(farm.token0.decimals);
  const token1Decimals = new BN(10).pow(farm.token1.decimals);
  const token0Supply = farm.vault?.lpInfo.token0Supply;
  const token1Supply = farm.vault?.lpInfo.token1Supply;

  const price = new BN(token0Supply)
    .times(token0Price)
    .div(token0Decimals)
    .plus(new BN(token1Supply).times(token1Price).div(token1Decimals))
    .div(farm.vault?.lpInfo.total_supply)
    .times(1e6);

  return price;
}

export function getTokenPricesFromFarm(data: (PublicFarmData | FarmData)[]) {
  const tokensBySymbols = {
    USDT: 1,
    USDC: 1,
    // DAI: 1,
    POINT: 0.0000001,
    // KAGE: 0.03,
  };

  const tokensByAddresses = {
    [USDT.address]: 1,
    [USDC.address]: 1,
    // [MAINNET_TOKEN_ADDRESSES["DAI"]]: 1,
    [POINT.address]: 0.0000001,
    // [MAINNET_TOKEN_ADDRESSES["KAGE"]]: 0.03,
  };

  const injUSDTFarm = data.filter((farm) => farm.name === "INJ-USDT LP");
  const dojoInjFarm = data.filter((farm) => farm.name === "DOJO-INJ LP");
  const otherFarms = data.filter(
    (farm) => !["INJ-UDST LP", "DOJO-INJ LP"].includes(farm.name)
  );
  for (const farm of [...injUSDTFarm, ...dojoInjFarm, ...otherFarms]) {
    if (!farm?.token0) {
      // console.log({
      //   farm,
      // })
    }

    if (!farm?.token0?.decimals || !farm?.token1?.decimals) {
      continue;
    }

    const token0Decimals = new BN(10).pow(farm.token0?.decimals);
    const token1Decimals = new BN(10).pow(farm.token1?.decimals);

    if (
      !tokensBySymbols[farm.token0.symbol] &&
      !!tokensBySymbols[farm.token1.symbol]
    ) {
      const ratio = new BN(farm.vault?.lpInfo.token1Supply)
        .div(token1Decimals)
        .div(new BN(farm.vault?.lpInfo.token0Supply).div(token0Decimals));
      const price = ratio.times(tokensBySymbols[farm.token1.symbol]).toNumber();
      if (!isNaN(price)) {
        if (IS_DEVELOPMENT && farm.token0.symbol === "INJ") {
          tokensBySymbols[farm.token0.symbol] = 36;
          tokensByAddresses[farm.token0.address] = 36;
        } else {
          tokensBySymbols[farm.token0.symbol] = price;
          tokensByAddresses[farm.token0.address] = price;
        }
      }
    }

    if (
      !tokensBySymbols[farm.token1.symbol] &&
      !!tokensBySymbols[farm.token0.symbol]
    ) {
      const ratio = new BN(farm.vault?.lpInfo.token0Supply)
        .div(token0Decimals)
        .div(new BN(farm.vault?.lpInfo.token1Supply).div(token1Decimals));
      const price = ratio.times(tokensBySymbols[farm.token0.symbol]).toNumber();
      if (!isNaN(price)) {
        tokensBySymbols[farm.token1.symbol] = price;
        tokensByAddresses[farm.token1.address] = price;
      }
    }
  }
  return [tokensBySymbols, tokensByAddresses];
}

// Re-fetching of the full farm data is selective, based on how stale each farm is
// TODO: Fetching of farm balance is specific, only make required calls
export const useFarms = () => {
  const { isConnected, wallet } = useWallet();
  const farmsByWallet = useEphemeralStore((s) => s.farmsByWallet);
  const cachedWallet = useEphemeralStore((s) => s.farmWallet);
  const cachedFarms =
    farmsByWallet?.[
      isConnected
        ? wallet.address
        : cachedWallet && cachedWallet !== ""
        ? cachedWallet
        : INJECTIVE_BURN_WALLET
    ];
  const setAllFarms = useEphemeralStore((s) => s.setAllFarms);
  const setFarm = useEphemeralStore((s) => s.setFarm);
  const setPricesBySymbol = useEphemeralStore((s) => s.setPricesBySymbol);
  const _pricesBySymbol = useEphemeralStore((s) => s.pricesBySymbol);
  const setPricesByTokenAddress = useEphemeralStore(
    (s) => s.setPricesByTokenAddress
  );
  const _pricesByTokenAddress = useEphemeralStore(
    (s) => s.pricesByTokenAddress
  );
  const selectedFarmsVersion = useEphemeralStore((s) => s.selectedFarmsVersion);
  // const pricesBySymbol = useStore((s) => s.pricesBySymbol);

  const { multicall } = useNetwork();
  const getURL = useURL();
  const { api } = useDashboardAPI();

  const key = NETWORK === Network.Testnet ? "testnet" : "mainnet";

  const hiddenFarms = (selectedFarmsVersion === 1 ? FARMS_V1 : FARMS_V2)[key]
    ?.filter((farm) => farm.hidden)
    .map((farm) => farm.name);

  const getFarmJson = (farmsToFetch: FarmConfig[], walletAddress: string) => {
    return _.flatMap(
      farmsToFetch.map((farm) => {
        const _queries = [
          {
            address: farm.stakingAddress,
            data: toBase64(StakingPool.StakingPoolQueryMsgBuilder.config()),
          },
          {
            address: farm.stakingAddress,
            data: toBase64(StakingPool.StakingPoolQueryMsgBuilder.state({})),
          },
          {
            address: farm.stakingAddress,
            data: toBase64(
              StakingPool.StakingPoolQueryMsgBuilder.stakerInfo({
                staker:
                  walletAddress && walletAddress !== ""
                    ? walletAddress
                    : INJECTIVE_BURN_WALLET,
                blockHeight: Math.round(Date.now() / 1000),
              })
            ),
          },
          {
            address: farm.lpAddress,
            data: toBase64({ token_info: {} }),
          },
          {
            address: farm.lpAddress,
            data: toBase64({ balance: { address: farm.stakingAddress } }),
          },
          {
            address: farm.contractAddress,
            data: toBase64({ pool: {} }),
          },
          {
            address:
              NETWORK === Network.Testnet
                ? TESTNET_TOKEN_ADDRESSES.DOJO
                : DOJO.address,
            data: toBase64({ balance: { address: farm.stakingAddress } }),
          },
        ];
        if (walletAddress !== "") {
          _queries.push({
            address: farm.lpAddress,
            data: toBase64({ balance: { address: walletAddress } }),
          });
        }
        return _queries;
      })
    );
  };

  // ------------------
  // EXECUTION
  // ------------------

  // Fetches only farms that NEED to be fetched every FETCH_FARMS_FREQUENCY_SECS.
  // Conditions are:
  // 1. Farm not hidden
  // 2. Farm is stale for more than CACHED_FARM_DURATION_SECS
  // 3. Wallet address has changed (if wallet is disconnected, dont refetch)
  // TODO: Stagger loading such that only maximum of 5-10 farms are refetched per FETCH_FARMS_FREQUENCY_SECS
  const fetchFarmsOnChain = async ({
    forceAll,
    forceFarms,
    version,
    walletAddress,
  }: {
    forceAll?: boolean;
    forceFarms?: string[];
    version: number;
    walletAddress: string;
  }) => {
    const farmsToFetch = await fetchFarmsFromJSON(
      version,
      cachedFarms,
      hiddenFarms,
      forceAll,
      forceFarms
    );
    // if (farmsToFetch.length > 0) {
    //   console.log(`fetching farms v${version}: `, farmsToFetch)
    // }
    const queries = getFarmJson(farmsToFetch, walletAddress);
    const publicFarms = await _fetchPublicFarmsOnChain(
      farmsToFetch,
      queries,
      walletAddress
    );
    const parsedFarms = parseFarms(publicFarms, version);
    return { parsedFarms, version, walletAddress };
  };

  const fetchFarms = async ({
    forceAll,
    forceFarms,
    forceOnChain,
    version,
    walletAddress,
  }: {
    forceAll?: boolean;
    forceFarms?: string[];
    forceOnChain?: boolean;
    version: number;
    walletAddress: string;
  }) => {
    if (!forceOnChain && !FORCE_ON_CHAIN_FETCH) {
      // fallback to fetching from on-chain if dashboard api fails
      try {
        const farmsToFetch = await fetchFarmsFromJSON(
          version,
          cachedFarms,
          hiddenFarms,
          forceAll,
          forceFarms
        );
        const { promise: timeout, cancel } = createTimeout(8);
        const publicFarms = await Promise.race([
          _fetchPublicFarmsFromAPI(
            farmsToFetch,
            version,
            walletAddress,
            forceAll,
            forceFarms
          ),
          timeout,
        ]);
        cancel();
        const parsedFarms = parseFarms(publicFarms as any, version);
        return { parsedFarms, version, walletAddress };
      } catch (e) {
        console.warn(
          "error on fetching farm via dashboard api, fetch from onchain",
          e
        );
        return fetchFarmsOnChain({
          forceAll,
          forceFarms,
          version,
          walletAddress,
        });
      }
    } else {
      return fetchFarmsOnChain({
        forceAll,
        forceFarms,
        version,
        walletAddress,
      });
    }
  };

  const fetchFarm = async (farmName: string, walletAddress: string) => {
    const farm = cachedFarms[`v${selectedFarmsVersion}`][farmName];
    setFarm(
      farmName,
      {
        ...farm,
        loading: true,
        lastFetched: undefined,
      },
      selectedFarmsVersion,
      walletAddress
    );
    await fetchFarms({
      version: selectedFarmsVersion,
      forceFarms: [farmName],
      forceOnChain: true,
      walletAddress,
    });
  };

  // ------------------
  // HELPERS
  // ------------------
  const _fetchPublicFarmsOnChain = async (
    farmsToFetch: FarmConfig[],
    queries: {
      address: any;
      data: string;
    }[],
    walletAddress: string
  ) => {
    try {
      const fetcher = multicallFetcher(multicall, getURL);
      const resp = await fetcher([queries]);

      const collectResults = (resp ?? []).reduce(
        (acc, curr) => acc.concat(curr.data.data.return_data),
        []
      );

      const data = collectResults?.map((e: any) => {
        return e.length == 0
          ? null
          : JSON.parse(Buffer.from(e.data, "base64").toString());
      });

      // 4 because query length is 4
      const chunkedResults = _.chunk(data, walletAddress !== "" ? 8 : 7);

      return farmsToFetch.map((farm, index) => {
        const config = chunkedResults?.[index]?.[0];
        const state = chunkedResults?.[index]?.[1];
        const staker = chunkedResults?.[index]?.[2];
        const lpInfo: any = chunkedResults?.[index]?.[3] ?? {};
        const stakedBalance: any = chunkedResults?.[index]?.[4] ?? {};
        const pair: any = chunkedResults?.[index]?.[5] ?? {};
        const dojoBalanceInStaking: any = chunkedResults?.[index]?.[6] ?? {};
        const walletBalance: any =
          chunkedResults?.[index]?.length > 7
            ? chunkedResults?.[index]?.[7]["balance"]
            : "0";

        return {
          ...farm,
          vault: {
            config,
            staker,
            state,
            total_rewards: dojoBalanceInStaking?.balance,
            stakedBalance: stakedBalance?.balance,
            lpInfo: {
              ...lpInfo,
              token0Supply: pair?.assets?.[0]?.amount,
              token1Supply: pair?.assets?.[1]?.amount,
            },
            walletBalance,
          },
        };
      }) as PublicFarmData[];
    } catch (e) {
      console.error("Error when fetching farm from chain", e?.stack || e);
    }
  };

  const _fetchPublicFarmsFromAPI = async (
    farmsToFetch: FarmConfig[],
    version: number,
    walletAddress: string,
    force?: boolean,
    forceFarms?: string[]
  ) => {
    const tvlData = await api.farms
      .getTvl({
        version,
        walletAddress,
        force,
        forceFarms,
      })
      .catch((e) => {
        throw e;
      });
    const missedFarmsArr = farmsToFetch.filter((farm) => !tvlData[farm.name]);
    // console.log("missedFarmsArr", missedFarmsArr)
    // const missedFarmsArr = farmsToFetch[0] ? [farmsToFetch[0]] : [];
    let missedFarmsData;
    if (missedFarmsArr.length > 0) {
      const queriesForMissedFarmFormApi = getFarmJson(
        missedFarmsArr,
        walletAddress
      );
      const publicFarms = await _fetchPublicFarmsOnChain(
        missedFarmsArr,
        queriesForMissedFarmFormApi,
        walletAddress
      );
      missedFarmsData = parseFarms(publicFarms, version);
    }
    // console.log({ farmsToFetch, tvlData })
    return farmsToFetch.map((farm) => {
      return {
        ...farm,
        ...(tvlData[farm.name]
          ? {
              vault: tvlData[farm.name].vault,
              tvl: tvlData[farm.name].tvl,
            }
          : {}),
        ...(missedFarmsData &&
        missedFarmsData.length &&
        missedFarmsData[farm.name]
          ? {
              vault: missedFarmsData[farm.name].vault,
              tvl: missedFarmsData[farm.name].tvl,
            }
          : {}),
      };
    }) as PublicFarmData[];
  };

  const parseFarms = (data: PublicFarmData[], version) => {
    const key = `v${version}`;
    const updatedFarms = {
      ...cachedFarms,
      [key]: {
        ...cachedFarms?.[key],
        ...data?.reduce((acc, obj) => {
          acc[obj.name] = obj;
          return acc;
        }, {}),
      },
    };
    const [pricesBySymbols, priceByTokenAddress] = getTokenPricesFromFarm(
      Object.values(updatedFarms["v2"] ?? {}) ?? []
    );
    setPricesBySymbol({ ..._pricesBySymbol, ...pricesBySymbols }); // farm pricing will take precedence over API pricing
    setPricesByTokenAddress({
      ..._pricesByTokenAddress,
      ...priceByTokenAddress,
    }); // farm pricing will take precedence over API pricing
    const dojoPrice = pricesBySymbols?.["DOJO"];
    let _farms = {};
    data?.forEach((item) => {
      if (!item?.token0?.decimals || !item?.token1?.decimals) {
        return;
      }
      const token0Price = pricesBySymbols[item.token0.symbol];
      const token1Price = pricesBySymbols[item.token1.symbol];
      const { distribution_schedule = [] } = item?.vault?.config ?? {};
      // const { amount } = item?.vault?.staker ?? {}
      const { pending_reward = "0" } = item?.vault?.staker ?? {};
      const { total_bond_amount } = item?.vault?.state ?? {};
      const lpPrice = getPriceOfLp(item, token0Price ?? 0, token1Price ?? 0);
      const apr = getAPR(
        distribution_schedule,
        total_bond_amount,
        new BN(dojoPrice).div(new BN(10).pow(18)),
        new BN(lpPrice).div(new BN(10).pow(6))
      );
      const emissions = getEmissions(distribution_schedule);
      const tvl = getTVL(item, token0Price ?? 0, token1Price ?? 0);
      // const totalSupply = new BN(item?.vault?.lpInfo?.total_supply).div(
      //   new BN(10).pow(6),
      // )
      // const lpPrice2 = new BN(tvl).div(item.vault?.stakedBalance).times(1e6)
      // const multiplier = 2
      // const dojoNetWorthMaxCommit = new BN(1)
      //   .div(lpPrice2)
      //   .times(dojoPrice)
      //   .times(multiplier)

      const balance = new BN(item?.vault?.staker?.bond_amount ?? "0")
        .div(BIG_TEN.pow(6))
        .toNumber();
      const balanceUsd = lpPrice.times(balance);
      const walletBalance = new BN(item?.vault?.walletBalance ?? "0").div(
        BIG_TEN.pow(6)
      );
      const walletUsd = walletBalance.times(lpPrice).toNumber();
      _farms[item.name] = {
        ...item,
        apr,
        balance,
        balanceUsd: !isNaN(balanceUsd.toNumber()) ? balanceUsd.toNumber() : 0,
        emissions: emissions === "NaN" ? 0 : emissions,
        pending_reward: new BN(pending_reward).div(BIG_TEN.pow(18)).toString(),
        total_bond_amount,
        tvl: new BN(tvl).toNumber(),
        walletBalance: walletBalance.toNumber(),
        walletUsd: walletUsd,
        lpPrice: lpPrice.toNumber(),
        lastFetched: dayjs().unix(),
        loading: false,
      } as FarmData;
    });
    return _farms;
  };

  async function fetchFarmsFromJSON(
    version: number,
    cachedFarms: any,
    hiddenFarms: string[],
    forceAll: boolean,
    forceFarms: string[]
  ) {
    const farmsToFetch = [];
    const now = dayjs();
    const farmResp = await fetch(`./json/farms_v${version}.json`);
    const farmData = await farmResp.json();
    const tokenResp = await fetch("./json/tokens-dictionary.json");
    const tokenData = await tokenResp.json();
    farmData
      ?.filter((farm) => !farm.hidden)
      ?.forEach((farm) => {
        const cachedFarm = cachedFarms?.[`v${version}`]?.[farm.name];
        // got to do this check cause we are looping through cached data
        if (!hiddenFarms.includes(farm.name)) {
          // only fetch if it has been stale for CACHED_FARM_DURATION_SECS
          if (
            forceAll ||
            forceFarms?.includes(farm.name) ||
            cachedFarm === undefined ||
            cachedFarm?.lastFetched === undefined ||
            now.diff(dayjs.unix(cachedFarm?.lastFetched), "seconds") >
              CACHED_FARM_DURATION_SECS
          ) {
            farm.token0 = tokenData[farm.token0];
            farm.token1 = tokenData[farm.token1];
            farmsToFetch.push(farm);
          }
        }
      });
    return farmsToFetch;
  }

  const { data } = useSWR(
    "farms",
    async () => {
      return await fetchFarms({
        version: selectedFarmsVersion,
        walletAddress: wallet.address,
      });
    },
    { refreshInterval: FETCH_FARMS_FREQUENCY_MS }
  );

  useEffect(() => {
    if (!!data?.parsedFarms && !!data?.version && !!data?.walletAddress) {
      setAllFarms(data?.parsedFarms, data?.version, data?.walletAddress);
    }
  }, [data]);

  return { fetchFarm, fetchFarms, farms: cachedFarms };
};
