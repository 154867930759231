import { WalletControllerChainOptions } from "@terra-money/wallet-provider";
import _ from "lodash";

const fallbackData = {
  mainnet: {
    name: "mainnet",
    api: "https://external.api.injective.network",
    chainID: "injective-1",
    hive: "https://phoenix-hive.terra.dev/graphql",
    lcd: _.sample([
      "https://inj18583.allnodes.me:1317/MOdKBWvqaA0m0ig3",
      "https://inj56634.allnodes.me:1317/lr77ZyGM7KCQbfkc",
      "https://inj24984.allnodes.me:1317/iAeAChGmajFpOeRk",
      "https://inj32954.allnodes.me:1317/4Wc21W4Wbkcxlrvm",
      // "https://sentry.lcd.injective.network:443",
      // "https://inj-rest.w3node.com/12d538c0dfd23b324739be8c7cea8e47e4f1fc6e7cf1360920c345ea35319438/api",
      // "https://02b39825-d9c8-4a30-8e88-34fd1c0617ca.injective-1.mesa-rest.newmetric.xyz",
      // "https://injective-rest.publicnode.com",
      // "https://sentry.lcd.injective.network:443",
    ]),
    walletconnectID: 1,
  },
  testnet: {
    api: "https://external.api.injective.network",
    chainID: "injective-888",
    hive: "https://phoenix-hive.terra.dev/graphql",
    lcd: "https://testnet.sentry.lcd.injective.network:443",
    name: "testnet",
    walletconnectID: 0,
  },
};
export async function getChainOptions() {
  let data: any;
  data = fallbackData;
  const chains = Object.values(data);
  const walletConnectChainIds = chains.reduce<
    WalletControllerChainOptions["walletConnectChainIds"]
  >((result, network: any) => {
    if (typeof network.walletconnectID === "number") {
      result[network.walletconnectID] = network;
    } else if (!result[1] && network.name === "mainnet") {
      result[1] = network;
    } else if (!result[0] && network.name === "testnet") {
      result[0] = network;
    }
    return result;
  }, {});
  const chainOptions: WalletControllerChainOptions = {
    defaultNetwork: walletConnectChainIds[1],
    walletConnectChainIds,
  };
  return chainOptions;
}
