import { create, StoreApi, UseBoundStore } from "zustand";
import { EphemeralStore } from "./interfaces/ephemeralStore.interface";
import { pricesSlice } from "./slices/pricesSlice";
import { farmsSlice } from "./slices/farmsSlice";
import { txStateSlice } from "./slices/txStateSlice";
import { mintsSlice } from "./slices/mintsSlice";
import { stakingSlice } from "./slices/stakingSlice";

let useEphemeralStore: UseBoundStore<StoreApi<EphemeralStore>>;

useEphemeralStore = create<EphemeralStore>()((...a) => ({
  ...pricesSlice(...a),
  ...farmsSlice(...a),
  ...txStateSlice(...a),
  ...mintsSlice(...a),
  ...stakingSlice(...a),
}));

export default useEphemeralStore;
