import classNames from "classnames";
import { Link, useLocation } from "react-router-dom";
import { Tooltip } from "react-tooltip";

interface Props {
  title: string;
  href: string;
  comingSoon?: boolean;
  external?: boolean;
}

export default function HeaderNavItem(props: Props) {
  const { title, href, external, comingSoon } = props;
  const { pathname } = useLocation();

  const isActive = (_pathname: string) => {
    if (_pathname === "/") {
      return pathname === "/";
    }
    if (pathname.includes(_pathname)) {
      return true;
    }
    return false;
  };

  if (comingSoon) {
    return (
      <div
        className="group"
        data-tooltip-id="coming-soon"
        data-tooltip-content="Coming Soon">
        <div className="min-h-16 hidden lg:flex font-medium items-center relative tracking-wider duration-200 px-4 cursor-wait text-slate-400">
          {title}
        </div>
        <Tooltip id="coming-soon" className="tooltip" />
      </div>
    );
  }

  return (
    <div className="group">
      {external ? (
        <a
          href={href}
          className={classNames(
            "min-h-16 hidden lg:flex font-medium items-center relative tracking-wider duration-200 px-4"
          )}>
          {title}
        </a>
      ) : (
        <Link
          to={href}
          className={classNames(
            "min-h-16 hidden lg:flex font-medium items-center relative tracking-wider duration-200 px-4"
          )}>
          {title}
        </Link>
      )}

      <div
        className={classNames(
          "h-px bg-gradient-to-r from-primary to-secondary transition-all duration-500 ease-in-out",
          isActive(href) ? "w-full" : "w-0 group-hover:w-full"
        )}
      />
    </div>
  );
}
