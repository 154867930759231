import React, { lazy, Suspense, useEffect } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import WalletConnectProvider from "./components/WalletConnectProvider";
// import { createPublicClient, http } from "viem";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import App from "components/App";
const Home = lazy(() => import("components/Home"));
const Stake = lazy(() => import("components/Stake"));
const Mint = lazy(() => import("components/Mint"));
const DotBackground = lazy(() => import("components/DotBackground"));
const PasswordGate = lazy(() => import("components/PasswordGate"));
const Allocation = lazy(() => import("components/Allocation"));
const Dashboard = lazy(() => import("components/Dashboard"));
const Convert = lazy(() => import("components/Convert"));
const Leaderboard = lazy(() => import("components/Leaderboard"));
const Join = lazy(() => import("components/Join"));
// const UpgradeInProgress = lazy(() => import("components/UpgradeInProgress"));
// import Footer from "components/Footer";
import "@rainbow-me/rainbowkit/styles.css";

import { RainbowKitProvider, getDefaultConfig } from "@rainbow-me/rainbowkit";
import { WagmiProvider } from "wagmi";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { mainnet } from "wagmi/chains";
// import Footer from "components/Footer";
// import MoneyMarket from "components/MoneyMarket";

const evmWalletConfig = getDefaultConfig({
  appName: "Injera",
  projectId: "YOUR_PROJECT_ID",
  chains: [mainnet],
  ssr: true, // If your dApp uses server side rendering (SSR)
});

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <WagmiProvider config={evmWalletConfig}>
        <QueryClientProvider client={queryClient}>
          <RainbowKitProvider>
            <WalletConnectProvider>
              <div className="relative">
                <App />
                <Routes>
                  <Route
                    path="/"
                    element={
                      <Suspense>
                        <Home />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/stake"
                    element={
                      <Suspense>
                        <PasswordGate>
                          <Stake />
                        </PasswordGate>
                      </Suspense>
                    }
                  />
                  <Route
                    path="/mint"
                    element={
                      <Suspense>
                        <Mint />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/join"
                    element={
                      <Suspense>
                        <Join />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/allocation"
                    element={
                      <Suspense>
                        <PasswordGate>
                          <Allocation />
                        </PasswordGate>
                      </Suspense>
                    }
                  />
                  <Route
                    path="/dashboard"
                    element={
                      <Suspense>
                        <PasswordGate>
                          <Dashboard />
                        </PasswordGate>
                      </Suspense>
                    }
                  />
                  {/* <Route
                    path="/money-market"
                    element={
                      <PasswordGate>
                        <MoneyMarket />
                      </PasswordGate>
                    }
                  /> */}
                  <Route
                    path="/convert"
                    element={
                      <Suspense>
                        <Convert />
                      </Suspense>
                    }
                  />
                  <Route
                    path="/leaderboard"
                    element={
                      <Suspense>
                        <Leaderboard />
                      </Suspense>
                    }
                  />
                </Routes>
                <Suspense>
                  <DotBackground />
                </Suspense>
                <ToastContainer pauseOnHover theme="dark" />
              </div>
            </WalletConnectProvider>
          </RainbowKitProvider>
        </QueryClientProvider>
      </WagmiProvider>
    </BrowserRouter>
  </React.StrictMode>
);
