import useSWR from "swr";
import useEphemeralStore from "../store/ephemeralStore";
import useDashboardAPI from "rest/useDashboardAPI.ts";
import { useEffect } from "react";
import { FETCH_FARMS_FREQUENCY_MS } from "./useFarms";

export const usePrices = () => {
  const tokenSymbolsMap = useEphemeralStore((s) => s.tokenSymbolsMap);
  const setPricesBySymbol = useEphemeralStore((s) => s.setPricesBySymbol);
  const setPricesByTokenAddress = useEphemeralStore(
    (s) => s.setPricesByTokenAddress
  );
  const { api } = useDashboardAPI();

  // ------------------
  // EXECUTION
  // ------------------
  const fetchPrices = async () => {
    const priceData = await api.prices.list();
    const _pricesBySymbol = {};
    const _pricesByTokenAddress = {};
    priceData?.map((price) => {
      _pricesBySymbol[price.symbol] = price.price;
      _pricesByTokenAddress[price.contractAddress] = price.price;
    });
    return {
      pricesBySymbol: _pricesBySymbol,
      pricesByTokenAddress: _pricesByTokenAddress,
    };
  };

  const { data } = useSWR(
    tokenSymbolsMap?.["inj"] !== undefined ? "prices" : null,
    async () => {
      const res = await fetchPrices();
      return res;
    },
    { refreshInterval: FETCH_FARMS_FREQUENCY_MS }
  );

  useEffect(() => {
    setPricesBySymbol(data?.pricesBySymbol);
    setPricesByTokenAddress(data?.pricesByTokenAddress);
  }, [data?.pricesBySymbol, data?.pricesByTokenAddress]);

  return;
};
