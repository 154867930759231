import useStore from "../store"
import { WalletConnectStatus } from "../types/enums"

const useAddress = () => {
  const wallet = useStore((s) => s.wallet)
  const isConnected =
    wallet.connectStatus === WalletConnectStatus.connected

  return isConnected ? wallet.address : ""
}

export default useAddress
