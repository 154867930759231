import "../styles/global.css";
import "react-tooltip/dist/react-tooltip.css";
import { useEffect } from "react";
import Header from "./Header";
import useWallet from "hooks/useWallet";
import { usePrices } from "hooks/usePrices";
import { useTokenSymbolsMap } from "rest/useDashboardAPI";
import { useFarms } from "hooks/useFarms";
import { useMint } from "hooks/useMint";
import { useAccount } from "wagmi";
import useReferrals from "rest/useReferrals";

const LOCALSTORAGE_KEYS_TO_CLEAR = ["injera"];

const App = () => {
  const { wallet } = useWallet();
  const account = useAccount();
  const {
    myReferral,
    hasReferralCode,
    myReferralLoading,
    createReferralCode,
    mutateMyReferral,
  } = useReferrals();
  // console.log({ myReferral, hasReferralCode });

  usePrices();
  useFarms();
  useMint();

  useTokenSymbolsMap();
  // const cachedNftWallet = useStore((s) => s.nftWallet);

  useEffect(() => {
    const run = async () => {
      console.log("Creating referral code");
      await createReferralCode(wallet.address);
      await mutateMyReferral();
    };

    if (!myReferralLoading && !hasReferralCode) {
      run();
    }
  }, [myReferral, hasReferralCode, wallet.address, myReferralLoading]);

  useEffect(() => {
    // Define your condition for clearing specific keys
    LOCALSTORAGE_KEYS_TO_CLEAR.forEach((key) => {
      localStorage.removeItem(key);
    });
  }, []);

  return <Header />;
};

export default App;
