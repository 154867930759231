import { USDI_MINTING_CONTRACT_ADDRESS } from "./constants";

export interface MintConfig {
  name: string;
  from: MintToken;
  to: MintToken;
  mintContractAddress: string;
  hidden?: boolean;
}

export interface MintToken {
  symbol: string;
  name: string;
  address: string;
  decimals: number;
  type: string;
}

export interface PublicMintData extends MintConfig {
  user: {
    fromBalance: number;
    fromAllowance: number;
    toBalance: number;
    toAllowance: number;
    canMint: boolean;
  };
}

export const MINT_TOKENS = {
  WETH: {
    address: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
    decimals: 18,
    name: "WETH",
    symbol: "WETH",
    type: "erc20",
  },
  WBTC: {
    address: "0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599",
    decimals: 8,
    name: "WBTC",
    symbol: "WBTC",
    type: "erc20",
  },
  INJ: {
    address: "0xe28b3B32B6c345A34Ff64674606124Dd5Aceca30",
    decimals: 18,
    name: "INJ",
    symbol: "INJ",
    type: "erc20",
  },
  STETH: {
    address: "0xae7ab96520DE3A18E5e111B5EaAb095312D7fE84",
    decimals: 18,
    name: "stETH",
    symbol: "STETH",
    type: "erc20",
  },
  USDT: {
    address: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
    decimals: 6,
    name: "USDT",
    symbol: "USDT",
    type: "erc20",
  },
  USDI: {
    address: "0x83E7D0451da91Ac509cd7F545Fb4AA04D4dD3BA8",
    decimals: 18,
    name: "USDI",
    symbol: "USDI",
    type: "erc20",
  },
};

export const mints: { mainnet: MintConfig[] } = {
  mainnet: [
    {
      name: "WETH/USDI",
      from: MINT_TOKENS.WETH,
      to: MINT_TOKENS.USDI,
      mintContractAddress: USDI_MINTING_CONTRACT_ADDRESS,
      hidden: false,
    },
    {
      name: "STETH/USDI",
      from: MINT_TOKENS.STETH,
      to: MINT_TOKENS.USDI,
      mintContractAddress: USDI_MINTING_CONTRACT_ADDRESS,
      hidden: false,
    },
    {
      name: "WBTC/USDI",
      from: MINT_TOKENS.WBTC,
      to: MINT_TOKENS.USDI,
      mintContractAddress: USDI_MINTING_CONTRACT_ADDRESS,
      hidden: false,
    },
    {
      name: "INJ/USDI",
      from: MINT_TOKENS.INJ,
      to: MINT_TOKENS.USDI,
      mintContractAddress: USDI_MINTING_CONTRACT_ADDRESS,
      hidden: false,
    },
    {
      name: "USDT/USDI",
      from: MINT_TOKENS.USDT,
      to: MINT_TOKENS.USDI,
      mintContractAddress: USDI_MINTING_CONTRACT_ADDRESS,
      hidden: false,
    },
  ],
};

export const getMintByFromTokenAddress = (address: string) => {
  const _mints = mints.mainnet;
  return _mints.find((item) => item.from.address === address);
};
