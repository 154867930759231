import { create, StoreApi, UseBoundStore } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
// import { devtools } from "zustand/middleware"

import { Store } from "./interfaces/store.interface";
import { walletSlice } from "./slices/walletSlice";

let useStore: UseBoundStore<StoreApi<Store>>;

useStore = create<Store>()(
  persist(
    (...a) => ({
      ...walletSlice(...a),
    }),
    {
      name: "injera1", // name of the item in the storage (must be unique)
      storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
    }
  )
);

export default useStore;
