import MESSAGE from "../lang/MESSAGE.json";
import Connected from "./Connected";
import useStore from "../store";
import { WalletConnectStatus } from "../types/enums";

const HeaderConnect = () => {
  const wallet = useStore((s) => s.wallet);
  const setShowWalletConnectModal = useStore(
    (s) => s.setShowWalletConnectModal
  );
  const handleClick = () => {
    setShowWalletConnectModal(true);
  };

  return !(wallet.connectStatus === WalletConnectStatus.connected) ? (
    <button
      id="connect"
      className="font-bold text-black rounded-lg flex items-center relative h-7 tracking-wider duration-200 px-4 bg-white uppercase hover:opacity-80"
      onClick={handleClick}>
      {MESSAGE.Wallet.Connect}
    </button>
  ) : (
    <Connected
      className={
        "flex text-sm font-semibold px-[15px] py-2 rounded-[14px] hover:no-underline p-2 border-1"
      }
    />
  );
};

export default HeaderConnect;
