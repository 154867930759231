import { StateCreator } from "zustand";
import { getEthereumAddress, getInjectiveAddress } from "@injectivelabs/sdk-ts";
import { isCosmosWallet, Wallet } from "@injectivelabs/wallet-ts";
import {
  confirmCorrectKeplrAddress,
  validateCosmosWallet,
} from "../../libs/cosmos";
import { confirm, connect, getAddresses } from "../../libs/wallet";
import { WalletConnectStatus } from "../../types/enums";
import { CHAIN_ID, ETHEREUM_CHAIN_ID } from "../../libs/services";
import { validateMetamask } from "../../libs/metamask";
import { WalletStore } from "../../types/wallet";
import { WalletSlice } from "../interfaces/wallet.interface";

export const isCosmosWalletDojo = (walletType: Wallet) => {
  if ((walletType as any) === "okx") {
    return true;
  }
  return isCosmosWallet(walletType);
};

const initialWalletState: WalletStore = {
  walletType: Wallet.Metamask,
  address: "",
  ethereumAddress: "",
  connectStatus: WalletConnectStatus.idle,
};

export const walletSlice: StateCreator<WalletSlice, [], [], WalletSlice> = (
  set,
  get
) => ({
  wallet: {
    ...initialWalletState,
  },
  mockWallet: "",
  setMockWallet: (wallet: string) =>
    set(() => ({
      mockWallet: wallet,
    })),
  setWalletStatusConnected: () =>
    set((state) => ({
      ...state,
      wallet: {
        ...state.wallet,
        connectStatus: WalletConnectStatus.connected,
      },
    })),
  connectWallet: async (wallet: Wallet) => {
    await connect({ wallet: wallet });
    set((state) => ({
      ...state,
      wallet: {
        ...state.wallet,
        walletType: wallet,
        isCosmosWallet: isCosmosWallet(wallet),
      },
    }));
  },
  connectMetamask: async () => {
    await get().connectWallet(Wallet.Metamask);

    const addresses = await getAddresses();
    const [ethereumAddress] = addresses;
    const addressConfirmation = await confirm(ethereumAddress);
    const address = getInjectiveAddress(ethereumAddress);

    set((state) => ({
      ...state,
      wallet: {
        ...state.wallet,
        ethereumAddress,
        address,
        addressConfirmation,
      },
    }));
    get().setWalletStatusConnected();
  },
  connectKeplr: async () => {
    await get().connectWallet(Wallet.Keplr);

    const injectiveAddresses = await getAddresses();
    const [address] = injectiveAddresses;
    const addressConfirmation = await confirm(address);
    const ethereumAddress = getEthereumAddress(address);
    await confirmCorrectKeplrAddress(address);

    set((state) => ({
      ...state,
      wallet: {
        ...state.wallet,
        ethereumAddress,
        address,
        addressConfirmation,
      },
    }));
    get().setWalletStatusConnected();
  },
  connectLeap: async () => {
    await get().connectWallet(Wallet.Leap);

    const injectiveAddresses = await getAddresses();
    const [address] = injectiveAddresses;
    const addressConfirmation = await confirm(address);
    const ethereumAddress = getEthereumAddress(address);

    set((state) => ({
      ...state,
      wallet: {
        ...state.wallet,
        ethereumAddress,
        address,
        addressConfirmation,
      },
    }));
    get().setWalletStatusConnected();
  },
  connectCosmoStation: async () => {
    await get().connectWallet(Wallet.Cosmostation);

    const injectiveAddresses = await getAddresses();
    const [address] = injectiveAddresses;
    const addressConfirmation = await confirm(address);
    const ethereumAddress = getEthereumAddress(address);

    set((state) => ({
      ...state,
      wallet: {
        ...state.wallet,
        ethereumAddress,
        address,
        addressConfirmation,
      },
    }));
    get().setWalletStatusConnected();
  },
  connectTrustWallet: async () => {
    await get().connectWallet(Wallet.TrustWallet);

    const addresses = await getAddresses();
    const [ethereumAddress] = addresses;
    const addressConfirmation = await confirm(ethereumAddress);
    const address = getInjectiveAddress(ethereumAddress);

    set((state) => ({
      ...state,
      wallet: {
        ...state.wallet,
        ethereumAddress,
        address,
        addressConfirmation,
      },
    }));
    get().setWalletStatusConnected();
  },
  connectWalletConnect: async () => {
    await get().connectWallet(Wallet.WalletConnect);

    const addresses = await getAddresses();
    const [ethereumAddress] = addresses;
    const addressConfirmation = await confirm(ethereumAddress);
    const address = getInjectiveAddress(ethereumAddress);

    set((state) => ({
      ...state,
      wallet: {
        ...state.wallet,
        ethereumAddress,
        address,
        addressConfirmation,
      },
    }));
    get().setWalletStatusConnected();
  },
  connectNinji: async () => {
    await get().connectWallet(Wallet.Ninji);

    const injectiveAddresses = await getAddresses();
    const [address] = injectiveAddresses;
    const addressConfirmation = await confirm(address);
    const ethereumAddress = getEthereumAddress(address);

    set((state) => ({
      ...state,
      wallet: {
        ...state.wallet,
        ethereumAddress,
        address,
        addressConfirmation,
      },
    }));
    get().setWalletStatusConnected();
  },
  connectLedger: async (ethereumAddress: string) => {
    await get().connectWallet(Wallet.Ledger);

    const addressConfirmation = await confirm(ethereumAddress);
    const address = getInjectiveAddress(ethereumAddress);

    set((state) => ({
      ...state,
      wallet: {
        ...state.wallet,
        ethereumAddress,
        address,
        addressConfirmation,
      },
    }));
    get().setWalletStatusConnected();
  },
  connectLedgerLegacy: async (ethereumAddress: string) => {
    await get().connectWallet(Wallet.LedgerLegacy);

    const addressConfirmation = await confirm(ethereumAddress);
    const address = getInjectiveAddress(ethereumAddress);

    set((state) => ({
      ...state,
      wallet: {
        ...state.wallet,
        ethereumAddress,
        address,
        addressConfirmation,
      },
    }));
    get().setWalletStatusConnected();
  },
  connectTrezor: async (ethereumAddress: string) => {
    await get().connectWallet(Wallet.Trezor);

    const addressConfirmation = await confirm(ethereumAddress);
    const address = getInjectiveAddress(ethereumAddress);

    set((state) => ({
      ...state,
      wallet: {
        ...state.wallet,
        ethereumAddress,
        address,
        addressConfirmation,
      },
    }));
    get().setWalletStatusConnected();
  },
  connectTorus: async () => {
    await get().connectWallet(Wallet.Torus);

    const addresses = await getAddresses();
    const [ethereumAddress] = addresses;
    const addressConfirmation = await confirm(ethereumAddress);
    const address = getInjectiveAddress(ethereumAddress);

    set((state) => ({
      ...state,
      wallet: {
        ...state.wallet,
        ethereumAddress,
        address,
        addressConfirmation,
      },
    }));
    get().setWalletStatusConnected();
  },
  validate: async () => {
    if (isCosmosWallet(get().wallet.walletType as Wallet)) {
      console.log("validate cosmos");
      await validateCosmosWallet({
        address: get().wallet.address ?? "",
        chainId: CHAIN_ID,
        wallet: get().wallet.walletType as Wallet,
      });
      return;
    }
    if (get().wallet.walletType === Wallet.Metamask) {
      console.log("validate metamask");
      await validateMetamask(
        get().wallet.ethereumAddress ?? "",
        ETHEREUM_CHAIN_ID
      );
    }
  },
  disconnect: async () =>
    set((state) => ({
      ...state,
      wallet: {
        ...state.wallet,
        address: "",
        ethereumAddress: "",
        connectStatus: WalletConnectStatus.disconnected,
      },
    })),
  showWalletConnectModal: false,
  setShowWalletConnectModal: (show: boolean) => {
    set((state) => ({
      ...state,
      showWalletConnectModal: show,
    }));
  },
});
