// need to write condition where fetching will occur
// need to store any failed requests and attempt after 30s. after 2 tries just give up
// when users refresh balances, only fetch the specific farm to reduce calls
// using axios with throttling should help: https://www.npmjs.com/package/axios-rate-limit

import { StateCreator } from "zustand";
import { StakingSlice, StakingData } from "../interfaces/staking.interface";
import { INJECTIVE_BURN_WALLET } from "constants/constants";

export const stakingSlice: StateCreator<StakingSlice, [], [], StakingSlice> = (
  set
) => ({
  stakingWallet: undefined,
  setStakingWallet: (wallet: string) =>
    set((state) => ({
      ...state,
      stakingWallet: wallet,
    })),
  stakingData: undefined,
  setStakingData: (staking: StakingData, wallet: string) => {
    const theWallet = wallet !== "" ? wallet : INJECTIVE_BURN_WALLET;
    set((state) => ({
      ...state,
      stakingData: {
        ...state.stakingData,
        [theWallet]: {
          ...staking,
        },
      },
    }));
  },
});
