import { Link, useNavigate, useLocation } from "react-router-dom";
import classNames from "classnames";
import { motion, useCycle } from "framer-motion";
import { useRef, useState } from "react";
import { Pivot as Hamburger } from "hamburger-react";
import HeaderConnect from "./HeaderConnect";
import useDimensions from "utils/useDimensions";
import HeaderConnectMobile from "./HeaderConnectMobile";
import { IS_DEVELOPMENT } from "libs/services";
import BuyModal from "./BuyModal";
import PriceChartModal from "./PriceChartModal";
import useEphemeralStore from "../store/ephemeralStore";
import HeaderNavItem from "./HeaderNavItem";
import Ticker, { TickerItem } from "./Ticker";

const Header = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [isOpen, toggleNav] = useCycle(false, true);
  const containerRef = useRef(null);
  const { height } = useDimensions(containerRef);
  const isHome = pathname === "/";
  const isWhiteLogo = isHome && !isOpen;
  const [showPriceChartModal, setShowPriceChartModal] = useState(false);
  const [showBuyModal, setShowBuyModal] = useState(false);
  const pricesBySymbol = useEphemeralStore((s) => s.pricesBySymbol);

  const isActive = (_pathname: string) => {
    if (_pathname === "/") {
      return pathname === "/";
    }
    if (pathname.includes(_pathname)) {
      return true;
    }
    return false;
  };

  const sidebar = {
    open: {
      transition: {
        type: "spring",
        duration: 0.75,
      },
      opacity: 1,
    },
    closed: {
      transition: {
        type: "spring",
        stiffness: 400,
        damping: 40,
      },
      opacity: 0,
    },
  };

  return (
    <>
      <header className="fixed w-full top-0 opacity-100 pointer-events-auto duration-200 max-w-full z-[999] border-b border-slate-800 bg-black">
        {/* <div className="text-white px-4 text-xs py-1.5 border-b border-slate-800 hidden">
          <Ticker>
            <>
              <TickerItem title="TVL" value="-" />
              <TickerItem title="USERS" value="-" />
              <TickerItem title="PROTOCOL APY" value="-" />
              <TickerItem title="USDi APY" value="-" />
              <TickerItem title="USDi MINTED" value="-" />
              <TickerItem title="TOTAL STAKED" value="-" />
              <TickerItem title="RESERVE FUNDS" value="-" />
              <TickerItem title="COLLATERALIZATION RATIO" value="-" />
              <TickerItem title="USDi PRICE" value="-" />
              <TickerItem title="GEMS ISSUED" value="-" />
            </>
          </Ticker>
        </div> */}
        <a
          href="https://dojo.trading/farms"
          target="_blank"
          rel="noreferrer"
          className="text-white px-4 text-sm py-1.5 border-b border-slate-800 flex items-center justify-center gap-x-2">
          <span className="relative h-2.5 w-2.5 flex">
            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-primary opacity-75"></span>
            <span className="relative inline-flex rounded-full h-2.5 w-2.5 bg-primary"></span>
          </span>
          <span className="flex items-center gap-x-1.5">
            Earn GEMs and farm up to 120% yields
            <span className="hidden sm:inline">
              {" "}
              with USDi/INJ on DojoSwap Today
            </span>
          </span>
        </a>
        <div className="flex h-full items-center justify-between max-w-11xl relative z-10 rounded-2xl px-4 mx-auto">
          <div className="shrink-0 mr-2">
            <div className="flex relative items-center text-white gap-x-3 text-sm">
              <div className="my-auto mr-6 group">
                <Link
                  to="/"
                  className={classNames(
                    "min-h-16 w-min-content relative flex items-center"
                  )}>
                  <img
                    src="/images/injera/logo.svg"
                    className={classNames("h-10 py-2 w-auto duration-30")}
                  />
                </Link>
                <div
                  className={classNames(
                    "h-px bg-gradient-to-r from-primary to-secondary transition-all duration-500 ease-in-out",
                    isActive("/") ? "w-full" : "w-0 group-hover:w-full"
                  )}
                />
              </div>
              <HeaderNavItem
                title="Money Market"
                href="https://market.injera.io"
                external
              />
              <HeaderNavItem title="Leaderboard" href="/leaderboard" />
              <HeaderNavItem title="Join" href="/join" />
              <HeaderNavItem title="Mint" href="/mint" />
              <HeaderNavItem title="Convert" href="/convert" />
              {/* <HeaderNavItem title="Stake" href="/stake" comingSoon /> */}
              {/* <HeaderNavItem title="Allocation" href="/allocation" />
              <HeaderNavItem title="Dashboard" href="/dashboard" /> */}
            </div>
          </div>

          <div className="hidden lg:flex">
            <div className="flex items-center text-white gap-x-3 text-sm">
              <a
                href="https://twitter.com/InjeraOfficial"
                target="_blank"
                rel="noreferrer"
                className="font-medium text-white rounded-lg uppercase flex items-center relative h-7 tracking-wider duration-200 px-4 bg-transparent border border-slate-800 hover:bg-slate-700">
                <img src="/images/twitter.svg" className="h-3.5 w-auto" />
              </a>
              <a
                href="https://t.me/InjeraOfficial"
                target="_blank"
                rel="noreferrer"
                className="font-medium text-white rounded-lg uppercase flex items-center relative h-7 tracking-wider duration-200 px-4 bg-transparent border border-slate-800 hover:bg-slate-700">
                <img src="/images/telegram.svg" className="h-3.5 w-auto" />
              </a>
              <a
                href="https://docs.injera.io"
                target="_blank"
                rel="noreferrer"
                className="font-medium text-white rounded-lg uppercase flex items-center relative h-7 tracking-wider duration-200 px-4 bg-transparent border border-slate-800 hover:bg-slate-700">
                <img src="/images/gitbook.svg" className="h-3.5 w-auto" />
              </a>
              <HeaderConnect />
            </div>
          </div>
          <div className="flex lg:hidden -mr-[14px] items-center gap-x-2">
            {/* <div
              onClick={() => setShowPriceChartModal(true)}
              className={classNames(
                "text-sm font-medium text-white rounded-lg uppercase flex items-center relative h-7 tracking-wider duration-200 px-2 bg-black backdrop-blur-sm bg-opacity-20 hover:bg-opacity-30 cursor-pointer"
              )}>
              <span className="mr-2 font-semibold">TVL</span>
              {pricesBySymbol?.["DOJO"]
                ? `$${(pricesBySymbol?.["DOJO"] * 10)?.toFixed(1)}M`
                : "-"}
              {pricesBySymbol?.["DOJO"] ? `$${12.482858?.toFixed(1)}M` : "-"}
            </div> */}
            <Hamburger
              toggled={isOpen}
              toggle={() => toggleNav()}
              size={20}
              color="#ffffff"
              duration={0.3}
            />
          </div>
        </div>
        <motion.nav
          initial={false}
          animate={isOpen ? "open" : "closed"}
          className={classNames("lg:hidden", isOpen ? "" : "hidden")}>
          <motion.div
            ref={containerRef}
            className="h-full w-full fixed top-0 right-0 bg-black"
            variants={sidebar}
            custom={height}>
            <div className="w-full overflow-y-auto px-4 pt-5 h-full">
              <div className="mt-20">
                <div className="-my-6 divide-y divide-white/20">
                  <div className="space-y-2 py-6">
                    {/* <div
                      onClick={() => {
                        navigate("/allocation");
                        toggleNav();
                      }}
                      className="block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white flex items-center justify-between cursor-pointer">
                      Airdrop
                    </div>
                    <div
                      className="block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white flex items-center justify-between cursor-pointer"
                    >
                      Allocation
                    </div> */}
                    {/* <div
                      onClick={() => {
                        setShowBuyModal(true);
                        toggleNav();
                      }}
                      className="block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white flex items-center justify-between cursor-pointer">
                      Buy
                    </div> */}
                    <a
                      href="https://market.injera.io"
                      className="rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:text-primary flex items-center justify-between cursor-pointer">
                      Money Market
                    </a>
                    <div
                      onClick={() => {
                        navigate("/leaderboard");
                        toggleNav();
                      }}
                      className="rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:text-primary flex items-center justify-between cursor-pointer">
                      Leaderboard
                    </div>
                    <div
                      onClick={() => {
                        navigate("/join");
                        toggleNav();
                      }}
                      className="rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:text-primary flex items-center justify-between cursor-pointer">
                      Join
                    </div>
                    {/* <div
                      onClick={() => {
                        navigate("/mint");
                        toggleNav();
                      }}
                      className="rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:text-primary flex items-center justify-between cursor-pointer">
                      Mint
                    </div> */}
                    {/* <div
                      onClick={() => {
                        navigate("/stake");
                        toggleNav();
                      }}
                      className="rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:text-primary flex items-center justify-between cursor-pointer">
                      Stake
                    </div> */}

                    <div
                      onClick={() => {
                        navigate("/convert");
                        toggleNav();
                      }}
                      className="rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:text-primary flex items-center justify-between cursor-pointer">
                      Convert
                    </div>
                    <div className="flex items-center justify-between gap-x-3 px-3 py-2">
                      <div className="rounded-lg text-base font-semibold leading-7 text-slate-500 flex items-center justify-between cursor-pointer">
                        Stake
                      </div>
                      <div className="text-white text-xs font-semibold bg-slate-700 px-2 py-0.5 rounded-md">
                        AVAILABLE SOON
                      </div>
                    </div>
                    {/* <div
                      onClick={() => {
                        navigate("/stake");
                        toggleNav();
                      }}
                      className="block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white flex items-center justify-between cursor-pointer">
                      Stake
                    </div> */}
                    {/* <div
                      onClick={() => {
                        navigate("/dashboard");
                        toggleNav();
                      }}
                      className="block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white flex items-center justify-between cursor-pointer">
                      Dashboard
                    </div> */}
                    <a
                      href="https://twitter.com/InjeraOfficial"
                      target="_blank"
                      rel="noreferrer"
                      className="rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white flex items-center justify-between">
                      <span className="block">Twitter</span>
                      <img
                        src="/images/twitter.svg"
                        className="h-4 w-auto block"
                      />
                    </a>
                    <a
                      href="https://t.me/InjeraOfficial"
                      target="_blank"
                      rel="noreferrer"
                      className="rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white flex items-center justify-between">
                      <span className="block">Telegram</span>
                      <img
                        src="/images/telegram.svg"
                        className="h-4 w-auto block"
                      />
                    </a>
                    <a
                      href="https://docs.injera.io"
                      target="_blank"
                      rel="noreferrer"
                      className="rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white flex items-center justify-between">
                      <span className="block">Docs</span>
                      <img
                        src="/images/gitbook.svg"
                        className="h-4 w-auto block"
                      />
                    </a>
                    {/* <a
                  href="https://medium.com/@dojoswap/%EF%B8%8F-the-first-cw404-on-injective-by-dojoswap-b56930def784"
                  target="_blank"
                  rel="noreferrer"
                  className="uppercase -ml-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white flex items-center justify-between">
                  <span className="block">MEDIUM</span>
                  <img
                    src="/images/medium.svg"
                    className="h-4 w-auto block"
                  />
                </a> */}
                  </div>
                  <div className="py-6">
                    <HeaderConnectMobile />
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        </motion.nav>
      </header>
      <PriceChartModal
        open={showPriceChartModal}
        onClose={() => setShowPriceChartModal(false)}
      />
      <BuyModal open={showBuyModal} onClose={() => setShowBuyModal(false)} />
    </>
  );
};

export default Header;
