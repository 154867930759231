import { useCallback, useEffect, useRef, useState } from "react";
import { truncate } from "../libs/text";
import { CHAIN_ID, IS_DEVELOPMENT } from "../libs/services";
import { ChainId } from "@injectivelabs/ts-types";
import useStore from "../store";
import {
  ArrowTopRightOnSquareIcon,
  CheckCircleIcon,
  ClipboardIcon,
} from "@heroicons/react/24/outline";

interface Props {
  className: string;
}

const Connected = ({ className }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const [showDetails, setShowDetails] = useState(false);
  const wallet = useStore((s) => s.wallet);
  const disconnect = useStore((s) => s.disconnect);

  const isMainnet = CHAIN_ID === ChainId.Mainnet;

  const handleMouseEnter = () => {
    setShowDetails(true);
  };

  const handleMouseLeave = () => {
    setShowDetails(false);
  };

  useEffect(() => {
    const handleTouchEnd = (event: any) => {
      const div = ref.current;

      if (div && !div.contains(event.target)) {
        handleMouseLeave();
      }
    };

    document.addEventListener("click", handleTouchEnd);
    document.addEventListener("touchend", handleTouchEnd);

    return () => {
      document.removeEventListener("click", handleTouchEnd);
      document.removeEventListener("touchend", handleTouchEnd);
    };
  }, []);

  const [isCopied, setIsCopied] = useState(false);
  const [isCopied2, setIsCopied2] = useState(false);

  const timer = useRef<ReturnType<typeof setTimeout>>();
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const inputRef2 = useRef<HTMLTextAreaElement>(null);

  const handleClick = useCallback<React.MouseEventHandler<HTMLButtonElement>>(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      if (timer?.current) {
        clearTimeout(timer.current);
      }
      setIsCopied(true);
      if (navigator?.clipboard?.writeText) {
        navigator?.clipboard?.writeText(inputRef?.current?.innerHTML || "");
      } else {
        inputRef?.current?.focus();
        inputRef?.current?.select();
        document.execCommand("copy");
      }

      if (event.currentTarget) {
        event.currentTarget.blur();
      }

      timer.current = setTimeout(() => {
        setIsCopied(false);
      }, 1200);
    },
    []
  );

  const handleClick2 = useCallback<React.MouseEventHandler<HTMLButtonElement>>(
    (event) => {
      event.preventDefault();
      event.stopPropagation();
      if (timer?.current) {
        clearTimeout(timer.current);
      }
      setIsCopied2(true);
      if (navigator?.clipboard?.writeText) {
        navigator?.clipboard?.writeText(inputRef2?.current?.innerHTML || "");
      } else {
        inputRef2?.current?.focus();
        inputRef2?.current?.select();
        document.execCommand("copy");
      }

      if (event.currentTarget) {
        event.currentTarget.blur();
      }

      timer.current = setTimeout(() => {
        setIsCopied2(false);
      }, 1200);
    },
    []
  );

  return (
    <div
      className={"relative rounded-lg"}
      ref={ref}
      onClick={() => handleMouseEnter()}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}>
      <button
        id="connect"
        className="font-medium rounded-lg flex items-center relative h-7 tracking-wider duration-200 px-1.5 ml-auto text-white bg-transparent border border-slate-800">
        <img
          src={`/images/wallets/${wallet.walletType}.svg`}
          alt={wallet.walletType}
          className="h-3.5 w-auto"
        />
        <span className={"font-semibold ml-2 mr-0 my-0 text-sm"}>
          {truncate(wallet.address)}
        </span>
      </button>
      <div
        className={`absolute z-[888] pt-3 right-0 top-7 ${
          showDetails ? "block" : "hidden"
        }`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}>
        <div className={"bg-white/20 text-white p-1 rounded-[10px]"}>
          <div className={"bg-black h-full w-80 p-4 rounded-[10px]"}>
            <div className="flex justify-between mb-3">
              <div className="flex items-center">
                <div className="font-bold mr-2">
                  My Account {IS_DEVELOPMENT && "(Testnet)"}
                </div>
                {/* <div onClick={() => toast.success()}><History size={16} /></div> */}
              </div>
              <div
                onClick={disconnect}
                className="text-white cursor-pointer font-semibold hover:opacity-80">
                Disconnect
              </div>
            </div>
            <div className="flex justify-between pb-1">
              <div className="flex items-center">
                <img
                  src={`/images/wallets/${wallet.walletType}.svg`}
                  alt={wallet.walletType}
                  className="h-3.5 w-auto"
                />
                <div className="ml-2 tracking-wider font-medium">
                  {truncate(wallet.address)}
                </div>
              </div>
              <div className="flex items-center gap-x-2">
                <button
                  onClick={handleClick}
                  className="cursor-pointer hover:brightness-75">
                  <textarea
                    ref={inputRef}
                    value={wallet.address}
                    readOnly
                    className="border-0 p-0 bg-transparent resize-none w-0 h-0 absolute -z-50"
                  />
                  {isCopied ? (
                    <CheckCircleIcon className="w-4 h-4" />
                  ) : (
                    <ClipboardIcon className="w-4 h-4" />
                  )}
                </button>

                <a
                  href={`https://${
                    !isMainnet ? "testnet." : ""
                  }explorer.injective.network/account/${wallet.address}`}
                  target="_blank"
                  rel="noreferrer"
                  className="cursor-pointer hover:brightness-75">
                  <ArrowTopRightOnSquareIcon className="w-4 h-4" />
                </a>
              </div>
            </div>
            <div className="pl-4 border-l-2 border-white">
              <div className="mt-3 text-sm font-semibold">Ethereum Address</div>
              <div className="flex justify-between">
                <div className="text-sm leading-7 tracking-wider font-medium">
                  {truncate(wallet.ethereumAddress)}
                </div>
                <div className="mb-1 flex items-center gap-x-2">
                  <button
                    onClick={handleClick2}
                    className="cursor-pointer hover:brightness-75">
                    <textarea
                      ref={inputRef2}
                      value={wallet.ethereumAddress}
                      readOnly
                      className="border-0 p-0 bg-transparent resize-none w-0 h-0 absolute -z-50"
                    />
                    {isCopied2 ? (
                      <CheckCircleIcon className="w-4 h-4" />
                    ) : (
                      <ClipboardIcon className="w-4 h-4" />
                    )}
                  </button>
                  <a
                    href={`https://etherscan.io/address/${wallet.ethereumAddress}`}
                    target="_blank"
                    rel="noreferrer"
                    className="cursor-pointer hover:brightness-75">
                    <ArrowTopRightOnSquareIcon className="w-4 h-4" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Connected;
