import BN from "bignumber.js";
import useEphemeralStore from "../store/ephemeralStore";

import { mints as MINTS, MintConfig, PublicMintData } from "constants/mints";
import _ from "lodash";
import dayjs from "dayjs";
import { MintData } from "store/interfaces/mints.interface";
import { multicall } from "@wagmi/core";
import TokenErc20ABI from "../constants/ABI/TokenErc20ABI.json";
import InjeraMinting from "../constants/ABI/InjeraMinting.json";

import { createConfig, http } from "wagmi";
import { useAccount } from "wagmi";
import { mainnet } from "wagmi/chains";
import {
  ETHEREUM_BURN_ADDRESS,
  USDI_MINTING_CONTRACT_ADDRESS,
} from "constants/constants";
import useSWR from "swr";
import { useEffect } from "react";

export const initialMints = () =>
  MINTS["mainnet"]
    ?.filter((mint) => !mint.hidden)
    .reduce((dict, obj) => {
      dict[obj.name] = {
        ...obj,
      };
      return dict;
    }, {});

// How often to CHECK if mints need to be fetched (in milliseconds)
export const FETCH_MINTS_FREQUENCY_MS = 30000;

// Mints will be cached for below duration,
// and not fetched whilst cached.
// Regardless of FETCH_MINTS_FREQUENCY_MS.
const CACHED_MINT_DURATION_SECS = 30;

export const useMint = () => {
  const account = useAccount();

  const cachedWallet = useEphemeralStore((s) => s.mintWallet);
  const mintsByWallet = useEphemeralStore((s) => s.mintsByWallet);

  const cachedMints =
    mintsByWallet?.[
      account.isConnected
        ? account.address
        : cachedWallet && cachedWallet !== ""
        ? cachedWallet
        : ETHEREUM_BURN_ADDRESS
    ];

  const setMintWallet = useEphemeralStore((s) => s.setMintWallet);
  const setAllMints = useEphemeralStore((s) => s.setAllMints);
  const setMint = useEphemeralStore((s) => s.setMint);

  const key = "mainnet";
  const allMints = MINTS[key]?.filter((mint) => !mint.hidden);
  const hiddenMints = MINTS[key]
    ?.filter((mint) => mint.hidden)
    .map((mint) => mint.name);

  const getMintJson = (mintsToFetch: MintConfig[]) => {
    return _.flatMap(
      mintsToFetch.map((mint) => {
        const _queries = [
          {
            address: mint.from.address,
            abi: TokenErc20ABI,
            functionName: "decimals",
            args: undefined,
          },
        ];
        if (account?.address) {
          _queries.push({
            address: mint.from.address,
            abi: TokenErc20ABI,
            functionName: "balanceOf",
            args: [account.address],
          });
          _queries.push({
            address: mint.from.address,
            abi: TokenErc20ABI,
            functionName: "allowance",
            args: [account.address, mint.mintContractAddress],
          });
          _queries.push({
            address: mint.to.address,
            abi: TokenErc20ABI,
            functionName: "balanceOf",
            args: [account.address],
          });
          _queries.push({
            address: mint.to.address,
            abi: TokenErc20ABI,
            functionName: "allowance",
            args: [account.address, mint.mintContractAddress],
          });
          _queries.push({
            address: USDI_MINTING_CONTRACT_ADDRESS,
            //@ts-ignore
            abi: InjeraMinting,
            functionName: "hasRole",
            args: [
              "0x44ac9762eec3a11893fefb11d028bb3102560094137c3ed4518712475b2577cc",
              account.address,
            ],
          });
        }

        return _queries;
      })
    );
  };

  const fetchMints = async (
    walletAddress: string,
    forceAll?: boolean,
    forceMints?: string[]
  ) => {
    return fetchMintsOnChain(walletAddress, forceAll, forceMints);
  };

  const fetchMintsOnChain = async (
    walletAddress: string,
    forceAll?: boolean,
    forceMints?: string[]
  ) => {
    const mintsToFetch = [];
    const now = dayjs();
    allMints.forEach((mint) => {
      const cachedMint = cachedMints?.[mint.name];
      // got to do this check cause we are looping through cached data
      if (!hiddenMints.includes(mint.name)) {
        // only fetch if it has been stale for CACHED_MINT_DURATION_SECS
        if (
          forceAll ||
          forceMints?.includes(mint.name) ||
          cachedMint === undefined ||
          cachedMint?.lastFetched === undefined ||
          now.diff(dayjs.unix(cachedMint?.lastFetched), "seconds") >
            CACHED_MINT_DURATION_SECS
        ) {
          mintsToFetch.push(mint);
        }
      }
    });
    // if (mintsToFetch.length > 0) {
    //   console.log("fetching mints: ", mintsToFetch);
    // }
    const queries = getMintJson(mintsToFetch);
    const mintsData = await fetchPublicMintsOnChain(
      mintsToFetch,
      queries,
      walletAddress
    );

    return { mintsData, walletAddress };
  };

  const fetchMint = async (mintName: string, walletAddress: string) => {
    const mint = cachedMints[mintName];
    setMint(
      mintName,
      {
        ...mint,
        loading: true,
        lastFetched: undefined,
      },
      walletAddress
    );
    await fetchMints(walletAddress, false, [mintName]);
  };

  // ------------------
  // HELPERS
  // ------------------

  const config = createConfig({
    chains: [mainnet],
    transports: {
      [mainnet.id]: http(),
    },
  });

  const fetchPublicMintsOnChain = async (
    mintsToFetch: MintConfig[],
    queries: {
      address: string;
      abi: any;
      functionName: string;
      args: any;
    }[],
    walletAddress: string
  ) => {
    //@ts-ignore
    const result = await multicall(config, { contracts: queries });

    const collectResults = (result ?? []).reduce(
      (acc, curr) => acc.concat(curr.result),
      []
    );

    const chunkedResults = _.chunk(collectResults, walletAddress ? 6 : 1);
    const allMints = mintsToFetch.map((mint, index) => {
      let _test: any = chunkedResults?.[index]?.[0];
      let fromBalance: any = chunkedResults?.[index]?.[1];
      let fromAllowance: any = chunkedResults?.[index]?.[2];
      let toBalance: any = chunkedResults?.[index]?.[3];
      let toAllowance: any = chunkedResults?.[index]?.[4];
      let canMint: any = chunkedResults?.[index]?.[5];

      // TODO: Testing Override
      // if (mint.name === "WETH/USDI") {
      //   fromBalance = new BN(16.9).times(10 ** mint.from.decimals);
      //   toBalance = new BN(25069).times(10 ** mint.to.decimals);
      //   fromAllowance = MAX_INT_UINT;
      //   toAllowance = MAX_INT_UINT;
      // }
      // if (mint.name === "WBTC/USDI") {
      //   fromBalance = new BN(2.369).times(10 ** mint.from.decimals);
      //   toBalance = new BN(25069).times(10 ** mint.to.decimals);
      //   fromAllowance = MAX_INT_UINT;
      //   toAllowance = MAX_INT_UINT;
      // }
      // if (mint.name === "INJ/USDI") {
      //   fromBalance = new BN(2769).times(10 ** mint.from.decimals);
      //   toBalance = new BN(25069).times(10 ** mint.to.decimals);
      //   fromAllowance = new BN(2000000000000000000000);
      //   toAllowance = MAX_INT_UINT;
      // }

      return {
        ...mint,
        user: {
          fromBalance: new BN(fromBalance)
            .div(10 ** mint.from.decimals)
            .toNumber(),
          fromAllowance: new BN(fromAllowance)
            .div(10 ** mint.from.decimals)
            .toNumber(),
          toBalance: new BN(toBalance).div(10 ** mint.to.decimals).toNumber(),
          toAllowance: new BN(toAllowance)
            .div(10 ** mint.to.decimals)
            .toNumber(),
          canMint,
        },
      };
    }) as PublicMintData[];

    let _mints = {};
    allMints.forEach((item) => {
      _mints[item.name] = {
        ...item,

        lastFetched: dayjs().unix(),
        loading: false,
      } as MintData;
    });
    return _mints;
  };

  const { data } = useSWR(
    "mint",
    async () => {
      return await fetchMints(account.address ?? "", true);
    },
    { refreshInterval: FETCH_MINTS_FREQUENCY_MS }
  );

  useEffect(() => {
    setAllMints(data?.mintsData, data?.walletAddress);
    setMintWallet(data?.walletAddress);
  }, [data]);

  return {
    fetchMint,
    fetchMints,
    mints: cachedMints,
  };
};
