// need to write condition where fetching will occur
// need to store any failed requests and attempt after 30s. after 2 tries just give up
// when users refresh balances, only fetch the specific farm to reduce calls
// using axios with throttling should help: https://www.npmjs.com/package/axios-rate-limit

import { StateCreator } from "zustand";
import { MintData, MintsMap, MintsSlice } from "../interfaces/mints.interface";
import { ETHEREUM_BURN_ADDRESS } from "constants/constants";

export const mintsSlice: StateCreator<MintsSlice, [], [], MintsSlice> = (
  set
) => ({
  mintWallet: undefined,
  setMintWallet: (wallet: string) =>
    set((state) => ({
      ...state,
      mintWallet: wallet,
    })),
  mintsByWallet: undefined,
  setAllMints: (mints: MintsMap, wallet: string) => {
    const theWallet = wallet !== "" ? wallet : ETHEREUM_BURN_ADDRESS;
    set((state) => ({
      ...state,
      mintsByWallet: {
        ...state.mintsByWallet,
        [theWallet]: {
          // ...state.mintsByWallet?.[theWallet],
          ...mints,
        },
      },
    }));
  },
  setMint: (name: string, mint: MintData, wallet: string) => {
    const theWallet = wallet !== "" ? wallet : ETHEREUM_BURN_ADDRESS;
    set((state) => ({
      ...state,
      mintsByWallet: {
        ...state.mintsByWallet,
        [theWallet]: {
          ...state.mintsByWallet?.[theWallet],
          [name]: mint,
        },
      },
    }));
  },
  selectedMint: undefined,
  setSelectedMint: (mint: MintData) =>
    set((state) => ({
      ...state,
      selectedMint: mint,
    })),
});
