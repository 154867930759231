export const DEFAULT_TOKEN_IMAGE_URL = "/images/tokens/default-token.svg";

export const INJECTIVE_BURN_WALLET =
  "inj1fj6syxy0wmvqhr3n57hwrljxwsng3k55t3yxgj";

export const ETHEREUM_BURN_ADDRESS =
  "0x0000000000000000000000000000000000000000";

export const DEPLOYER_ADDRESS = "inj1x26aln79hxrfm8c6v00208wlumrc0l6qtrtn8y";
export const DOJO_INJ_LP = "DOJO-INJ LP";
export const DOJO_INJ_LP_ADDRESS = "inj17pda96ujt7fzr3d5jmfkh4dzvrqzc0nk56kt34";
export const SUSDI_ADDRESS = "inj1xrqds8kuz76d6e48z9swhk3zr0ygy7zajy6rg7";

export const FORCE_ON_CHAIN_FETCH = true;
export const DAOJO_TREASURY_ADDRESS =
  "inj1jvysf42cn78p568sfvr05s4y38sp562s8vn72q";

export const MAX_INT_UINT =
  "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff";

export const USDI_MINTING_CONTRACT_ADDRESS =
  "0xB45f70AA5c1af8C15B0861a8cCF9fD4900F631b5";
