import useStore from "../store";

const ConnectList = () => {
  const [
    setShowWalletConnectModal,
    connectMetamask,
    connectKeplr,
    connectLeap,
    connectTrustWallet,
  ] = useStore((s) => [
    s.setShowWalletConnectModal,
    s.connectMetamask,
    s.connectKeplr,
    s.connectLeap,
    s.connectTrustWallet,
  ]);

  const handleConnectClick = () => {
    setShowWalletConnectModal(false);
  };

  return (
    <div className="flex flex-col gap-y-3">
      <div
        className="border border-black/10 hover:border-black transition-all duration-300 p-4 cursor-pointer rounded-xl flex items-center justify-between"
        onClick={() => {
          connectMetamask()
            .then(() => {
              console.info("connect metamask success");
              handleConnectClick();
            })
            .catch((e) => console.error(e));
        }}>
        <div className="text-black font-semibold tracking-wide">METAMASK</div>
        <img
          src="/images/wallets/metamask.svg"
          alt="Metamask"
          className="w-8 h-8"
        />
      </div>
      <div
        className="border border-black/10 hover:border-black transition-all duration-300 p-4 cursor-pointer rounded-xl flex items-center justify-between"
        onClick={() => {
          connectKeplr()
            .then(() => {
              console.info("connect keplr success");
              handleConnectClick();
            })
            .catch((e) => console.error(e));
        }}>
        <div className="text-black font-semibold tracking-wide">KEPLR</div>
        <img
          src="/images/wallets/keplr.svg"
          alt="Metamask"
          className="w-8 h-8"
        />
      </div>
      <div
        className="border border-black/10 hover:border-black transition-all duration-300 p-4 cursor-pointer rounded-xl flex items-center justify-between"
        onClick={() => {
          connectLeap()
            .then(() => {
              console.info("connect leap success");
              handleConnectClick();
            })
            .catch((e) => console.error(e));
        }}>
        <div className="text-black font-semibold tracking-wide">LEAP</div>
        <img
          src="/images/wallets/leap.svg"
          alt="Metamask"
          className="w-8 h-8"
        />
      </div>
      {/* <div
        className="border hover:opacity-90 p-4 cursor-pointer rounded-xl flex items-center justify-between"
        onClick={() => {
          connectTrustWallet()
            .then(() => {
              console.info("connect trust success");
              handleConnectClick();
            })
            .catch((e) => console.error(e));
        }}>
        <div className="text-white font-semibold tracking-wide">
          TRUST WALLET
        </div>
        <img src="/images/wallets/trust-wallet.svg" alt="Metamask" {...size} />
      </div> */}
    </div>
  );
};

export default ConnectList;
